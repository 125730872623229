import React from 'react';
import XmasData from '../component/XmasData';

const Xmas = () => {
    return (
        <div>
            <XmasData />
        </div>
    );
};

export default Xmas;