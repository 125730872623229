import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import presentData from "../data/presentData.json";
import Box from '@mui/material/Box';
import moment from 'moment';
import { BrowserView, MobileView } from 'react-device-detect';

const GifToday = () => {
    const { id } = useParams();
    let IdNumber = id ? parseInt(id) : 0;
    const DisplayData = presentData.present;
    let present: any;
    const presentToday = (DisplayData.map<any>(p => {
        if (p.id === IdNumber) {
            const msg = [];
            present = p;
            for (let i = 0; i < p.text.length; i++) {
                msg.push(
                    <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="20px"
                        fontSize="20px"
                    >{p.text[i]}</Typography>)
            }
            return (msg)
        }
    }))
    const date = moment(present.date).format("DD/MM/YYYY")
    return (
        <Grid>
            <BrowserView>
                <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                >
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            padding: "30px",
                            borderRadius: "25% 10%"
                        }}>
                        <Typography
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            variant="h2"
                            fontSize="40px"
                            color="#b2102f"
                            marginTop="60px"
                            paddingLeft="20px"
                        >{date}</Typography>
                        <Typography
                            paddingLeft="15%"
                            paddingRight="15%"
                        >
                            {presentToday}</Typography>
                    </Box>
                    <Link to={"/noel"} style={{
                        textDecoration: 'none', backgroundImage: "url('../img/return.png')", width: 200,
                        height: 150, backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        marginLeft: '150px'
                    }}></Link>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    style={{
                        backgroundColor: "#020929"
                    }}>
                    <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="#F9FBFD"
                        variant="h2"
                        fontSize="30px"
                    >{date}</Typography>
                    <Typography
                        margin="20%"
                        color="#F9FBFD"
                    >
                        {presentToday}</Typography>
                    <Link to={"/noel"} style={{
                        textDecoration: 'none', backgroundImage: "url('../img/return.png')", width: 200,
                        height: 150, backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                    }}></Link>
                </Grid >
            </MobileView>
        </Grid>
    )
}

export default GifToday;