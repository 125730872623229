import { AppBar, Grid, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
    return (
        <AppBar position="static" style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            boxShadow: "0px 0px 0px "
        }}
        >
            <Grid
                container
                display="flex" justifyContent="center" alignItems="center"
                style={{
                    backgroundImage: "url('../img/footer.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingBottom: "240px",
                    marginBottom: "-20px"
                }}>

            </Grid>
            <Grid xs={12} display="flex" justifyContent="center" alignItems="center" style={{ backgroundColor: "#ffffff", }}>
                <Typography style={{
                    color: "#020929",
                    fontFamily: "FlyingBird"
                }}>
                    © nanaplouf 2022
                </Typography>
            </Grid>
        </AppBar >
    );
};

export default Footer;