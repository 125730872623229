import React from 'react';
import { Present } from "../type/PresentType";
import presentData from "../data/presentData.json";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';

const XmasData = () => {

    const DisplayData = presentData.present.map<any>(
        (info: Present, index: number) => {
            const textArray = info.text.map((text, index) => {
                return (
                    <div key={index}>
                        <p>{text}</p>
                    </div>
                );
            });
            let id = 'panel' + info.id + 'bh-content';
            let aria = 'panel' + info.id + 'bh-header';
            const dateToday = moment().format("DD/MM/YYYY");
            const date = moment(info.date).format("DD/MM/YYYY")
            let text;
            if (dateToday >= date) {
                text = textArray
            } else {
                text = "un peu de patience... nous ne somme que le " + dateToday
            }
            return (
                <Grid item xs={7}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={aria}
                            id={id}
                        >
                            <Typography
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color="#b2102f"
                                variant="h2"
                                fontSize="30px">{date}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {text}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
            );
        }
    );
    return (
        <div>
            <BrowserView>
                <Grid container display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    spacing={5}>
                    {DisplayData}
                    <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                        <Link style={{ textDecoration: 'none' }} to={'/noel'}>
                            <Box
                                sx={{
                                    backgroundImage: "url('../img/return.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    width: 300,
                                    height: 300,
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    spacing={5}
                    style={{ backgroundColor: "#020929" }}
                >
                    {DisplayData}
                    <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                        <Link style={{ textDecoration: 'none' }} to={'/noel'}>
                            <Box
                                sx={{
                                    backgroundImage: "url('../img/return.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    width: 200,
                                    height: 200,
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </MobileView>

        </div >
    );
}

export default XmasData;