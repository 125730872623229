import React from "react";
import presentData from "../data/presentData.json";
import { Present } from "../type/PresentType";
import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { BrowserView, MobileView } from 'react-device-detect';


const Gif = () => {
  let date = moment().format("YYYY/MM/DD");
  console.log("la date du jour: ",date)

  const data = presentData.present
  const dataRand = [];

  for (let i = 0; i < data.length; i++) {
    let j = Math.floor(Math.random() * (i + 1));
    let k = data[i];
    data[i] = data[j];
    data[j] = k;
    dataRand.push(data)
  }

  function valueImg() {
    let range = { min: 40, max: 140 }
    let delta = range.max - range.min
    const rand = Math.round(range.min + Math.random() * delta)
    return (rand + "px")
  }

  const display = data.map<any>(
    (info: Present, index: number) => {
      let to = '/noel';
      if (date === info.date) {
        to = '/noel/' + info.id;
      }
      let width = valueImg();
      let height = valueImg();
      if (date <= info.date) {
        return (
          <Grid>
            <BrowserView>
              <Grid item xs={6} sm={3} md={2} display="flex" justifyContent="center" alignItems="center" margin={2}>
                <Grid >
                  <Link style={{ textDecoration: 'none', fontFamily: "FlyingBird" }} to={to}>
                    <Box
                      sx={{
                        backgroundImage: "url('../img/gif.png')",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        width: { width },
                        height: { height },
                      }}
                    >
                      <Box
                        sx={{
                          opacity: [0.1, 0.1],
                          '&:hover': {
                            opacity: [1, 1]
                          }
                        }}
                      >
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h2"
                          fontSize="50px"
                          color="#C99700"
                        >{info.id}</Typography>
                      </Box>
                    </Box >
                  </Link>
                </Grid>
              </Grid >
            </BrowserView>
            <MobileView>
              <Grid xs={6}
                margin={2}
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="20px">
                <Link style={{ textDecoration: 'none' }} to={to}>
                  <Box
                    sx={{
                      backgroundImage: "url('../img/gif.png')",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      width: { width },
                      height: { height },
                    }}
                  >
                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      variant="h2"
                      fontSize="50px"
                      color="#C99700"
                    >{info.id}</Typography>
                  </Box >
                </Link>
              </Grid >
            </MobileView >
          </Grid>
        );
      }
      return (
        <Grid item xs={6} sm={3} md={2} display="flex" justifyContent="center" alignItems="center">
          <Grid >
            <Link style={{ textDecoration: 'none' }} to={to}>
              <Box
                sx={{
                  backgroundImage: "url('../img/gifOpen.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  width: { width },
                  height: { height },
                }}
              >
                <Typography
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  variant="h2"
                  fontSize="50px"
                  color="#B08400"
                >{info.id}</Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      );
    }
  )
  return (
    <>
      <BrowserView>
        <Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
          >
            {display}
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
          >
            <Link style={{ textDecoration: 'none' }} to={'/presentAll'}>
              <Box
                sx={{
                  backgroundImage: "url('../img/1.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  width: 300,
                  height: 300,
                }}
              />
            </Link>
          </Grid>
        </Grid>

      </BrowserView>
      <MobileView>
        <Grid
          style={{ backgroundColor: "#020929", margin: "-10px" }}>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
          >
            {display}
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
          >
            <Link style={{ textDecoration: 'none' }} to={'/presentAll'}>
              <Box
                sx={{
                  backgroundImage: "url('../img/1.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  width: 300,
                  height: 300,
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </MobileView>
    </>
  );
};

export default Gif;
