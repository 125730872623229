import React from "react";
import Container from '@mui/material/Container';
import {Typography} from "@mui/material";
import {Link} from "react-router-dom";

const Rgpd = () => {
    return (
        <div>
            <Container
                maxWidth="xl"
            >
                <Typography variant="h1" color={"#ffffff"} margin={"20px"} fontSize={"60px"}>Politique de confidentialité</Typography>
                <Typography variant="h2" color={"#b20215"} margin={"20px"} fontSize={"40px"}>L'atelier de Noël</Typography>
                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 1 : PRÉAMBULE</Typography>
                <Typography variant="body1" color={"#ffffff"}>La présente politique de confidentialité a pour but d’informer les utilisateurs du site :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d’identifier un utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive) ; </Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Sur les droits dont ils disposent concernant ces données;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Sur la personne responsable du traitement des données à caractère personnel collectées et traitées;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Sur les destinataires de ces données personnelles;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Sur la politique du site en matière de cookies.</Typography>
                <Typography variant="body1" color={"#ffffff"}>Cette politique complète les mentions légales et les Conditions Générales d’Utilisation consultables par les utilisateurs à l’adresse suivante :  </Typography>
                <Link to={"/mentionslegales"} style={{
                    color: "#b20215",
                    paddingTop: "10px",
                    fontSize: "20px",
                }}> ➡️ Mentions Légales</Link>
                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES DONNÉES PERSONNELLES</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel sont :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Traitées de manière licite, loyale et transparente au regard de la personne concernée;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Collectées pour des finalités déterminées (cf. Article 3.1 des présentes), explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Exactes et, si nécessaire, tenues à jour. Toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Traitées de façon à garantir une sécurité appropriée des données collectées, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées.</Typography>
                <Typography variant="body1" color={"#ffffff"} marginTop={"20px"}>Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie : </Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</Typography>
                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</Typography>
             
                <Typography variant="caption" color={"#ffffff"} fontSize={"25px"}>Article 3.1 : Données collectées</Typography>
                <Typography variant="body1" color={"#ffffff"}>Les données personnelles collectées dans le cadre de notre activité sont les suivantes :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Adresse email</Typography>

                <Typography variant="caption" color={"#ffffff"} fontSize={"25px"}>Article 3.2 : Mode de collecte des données</Typography>
                <Typography variant="body1" color={"#ffffff"}>Lorsque vous utilisez notre site, sont automatiquement collectées les données suivantes :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Adresse email</Typography>
                <Typography variant="body1" color={"#ffffff"}>D’autres données personnelles sont collectées lorsque vous effectuez les opérations suivantes sur la plateforme :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>- Ajout d'un cadeau</Typography>

                <Typography variant="body1" color={"#ffffff"}>Elles sont conservées par le responsable du traitement dans des conditions raisonnables de sécurité, pour une durée de : 3 ans</Typography>
                <Typography variant="body1" color={"#ffffff"}>La société est susceptible de conserver certaines données à caractère personnel au-delà des délais annoncés ci-dessus afin de remplir ses obligations légales ou réglementaires.</Typography>

                <Typography variant="caption" color={"#ffffff"} fontSize={"25px"}>Article 3.3 : Hébergement des données</Typography>
                <Typography variant="body1" color={"#ffffff"}>L'application L'atelier de Noël est hébergé par :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Vera Dos Santos</Typography>

                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES</Typography>

                <Typography variant="caption" color={"#ffffff"} fontSize={"25px"}>Article 4.1 : Le responsable du traitement des données</Typography>
                <Typography variant="body1" color={"#ffffff"}>Le responsable du traitement des données à caractère personnel peut être contacté de la manière suivante :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Par mail : vera78ds@gmail.com.</Typography>

                <Typography variant="caption" color={"#ffffff"} fontSize={"25px"}>Article 4.2 : Le délégué à la protection des données</Typography>
                <Typography variant="body1" color={"#ffffff"}>Le délégué à la protection des données de l’entreprise ou du responsable est :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Vera Dos Santos</Typography>
                <Typography variant="body1" color={"#ffffff"}>Si vous estimez, après nous avoir contactés, que vos droits “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser une information à la CNIL.</Typography>

                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES</Typography>

                <Typography variant="body1" color={"#ffffff"}>Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants, en application du règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi 78-17 du 6 janvier 1978) :</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit d’accès, de rectification et droit à l’effacement des données (posés respectivement aux articles 15, 16 et 17 du RGPD) ;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit à la portabilité des données (article 20 du RGPD) ;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit à la limitation (article 18 du RGPD) et à l’opposition du traitement des données (article 21 du RGPD) ;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé ;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit de déterminer le sort des données après la mort ;</Typography>
                <Typography variant="body1" color={"#ffffff"} marginLeft={"20px"}>Droit de saisir l’autorité de contrôle compétente (article 77 du RGPD).</Typography>
                <Typography variant="body1" color={"#ffffff"}>Pour exercer vos droits, veuillez adresser votre courrier par mail à vera78ds@gmail.com. Afin que le responsable du traitement des données puisse faire droit à sa demande, l’utilisateur peut être tenu de lui communiquer certaines informations telles que : ses noms et prénoms, son adresse e-mail.</Typography>
                <Typography variant="body1" color={"#ffffff"}>Consultez le site cnil.fr pour plus d’informations sur vos droits.</Typography>

                <Typography variant="h3" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</Typography>

                <Typography variant="body1" color={"#ffffff"}>L’éditeur du site L'atelier de Noël se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs du site sa conformité avec le droit en vigueur.</Typography>
                <Typography variant="body1" color={"#ffffff"}>Les éventuelles modifications ne sauraient avoir d’incidence sur les achats antérieurement effectués sur le site, lesquels restent soumis à la Politique en vigueur au moment de l’achat et telle qu’acceptée par l’utilisateur lors de la validation de l’achat.</Typography>
                <Typography variant="body1" color={"#ffffff"}>L’utilisateur est invité à prendre connaissance de cette Politique à chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de l’en prévenir formellement.</Typography>
                <Typography variant="body1" color={"#ffffff"}>La présente politique, éditée le 14/11/2023 a été mise à jour le 14/11/2023.</Typography>
            </Container>
        </div>
    );
};

export default Rgpd;
