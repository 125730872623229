import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Grid, Link } from '@mui/material';

interface FullName {

    name: string;


}
const NavChrismas = (props:FullName) => {

    return (

        <AppBar position="static" style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            boxShadow: "0px 0px 0px "
        }}
        >
            <Grid
                container
                display="flex" justifyContent="center" alignItems="center"
                style={{
                    backgroundImage: "url('../img/nav.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingBottom: "160px",
                    marginBottom: "-20px"
                }}>
                <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link href="/"
                        style={{
                            textDecoration: "none",
                            color: "#020929",
                            fontSize: "45px",
                            fontFamily: "FlyingBird",
                        }}>{props.name}</Link>
                </Grid>
            </Grid>
        </AppBar >

    );
}

export default NavChrismas;
