import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, Grid } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Footer from './component/Footer';
import NavChrismas from './component/NavChrismas';

const theme = createTheme({
  typography: {
    fontFamily: 'FlyingBird',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'FlyingBird';
          src: url('./font/FlyingBird.otf');
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid style={{
        backgroundImage: "url('../img/fond.jpg')",
        backgroundSize: "80%",
        backgroundPosition: "center",
      }}>
        <NavChrismas name={"L'atelier de Noël"}/>
        <App />
        <Footer />
      </Grid>
    </ThemeProvider>
  </React.StrictMode>
);
