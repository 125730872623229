import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PresentId from "./pages/PresentId";
import Xmas from "./pages/Xmas";
import HomeNoel from "./pages/HomeNoel";
import Mentions from "./pages/Mentions";
import Rgpd from "./pages/Rgpd";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/noel" element={<HomeNoel />} />
        <Route path="/mentionslegales" element={<Mentions />} />
        <Route path="/rgpd" element={<Rgpd />} />
        <Route path="/noel/:id" element={<PresentId />} />
        <Route path="/presentAll" element={<Xmas />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
