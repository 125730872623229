import React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BrowserView, MobileView } from 'react-device-detect';

const Home = () => {
    return (
        <>
            <BrowserView>
                <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="5%"
                    marginBottom="5%"
                    spacing={2}
                >
                    <Grid item xs={5} display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Box
                            sx={{
                                width: 300,
                                height: 300,
                                backgroundImage: "url('../img/2.png')",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                            }}
                        >
                        </Box>
                    </Grid>
                    <Grid item xs={5} display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Link to={"/noel"} style={{
                            textDecoration: 'none',
                            color: "white",
                            paddingTop: "10px",
                            fontSize: "40px",
                        }}>Noël by nanaplouf</Link>
                    </Grid>
                </Grid >
            </BrowserView>
            <MobileView>
                <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    style={{
                        backgroundColor: "#020929"
                    }}>
                    <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="5%"
                        marginBottom="5%"
                        spacing={2}
                    >
                        <Grid item xs={8} display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <Box
                                sx={{
                                    width: 300,
                                    height: 300,
                                    backgroundImage: "url('../img/2.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                }}
                            >
                            </Box>
                        </Grid>
                        <Grid item xs={8} display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <Link to={"/noel"} style={{
                                textDecoration: 'none',
                                color: "white",
                                paddingTop: "10px",
                                fontSize: "40px",
                                textAlign: "center",
                            }}>Noël by nanaplouf</Link>
                        </Grid>
                    </Grid >
                </Grid>
            </MobileView>
        </>
    );
};

export default Home;
