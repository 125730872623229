import React from "react";
import Gif from "../component/Gif";
import Container from '@mui/material/Container';

const HomeNoel = () => {
    return (
        <div>
            <Container
                maxWidth="xl"
            >
                <Gif />
            </Container>
        </div>
    );
};

export default HomeNoel;
