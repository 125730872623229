import React from 'react';
import GifToday from '../component/GifToday';

const PresentId = () => {
    return (
        <div>
            <GifToday />
        </div>
    );
};

export default PresentId;