import React from "react";
import Container from '@mui/material/Container';
import {Typography} from "@mui/material";

const Mentions = () => {
    return (
        <div>
            <Container
                maxWidth="xl"
            >
                    <Typography variant="h1" color={"#ffffff"} margin={"20px"} fontSize={"60px"}>MENTIONS LÉGALES</Typography>
                    <Typography variant="caption" color={"#ffffff"} fontSize={"20px"}>En vigueur au 14/11/2023</Typography>
                    <Typography variant="body1" color={"#ffffff"} marginTop={"20px"}>
                        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site L'atelier de Noel , ci-après le "Site", les présentes mentions légales.
                    </Typography>
                    <Typography variant="body1" color={"#ffffff"}>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</Typography>
                    <Typography variant="body1" color={"#ffffff"}>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</Typography>
                    <Typography variant="h2" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 1 - L'EDITEUR</Typography>
                    <Typography variant="body1" color={"#ffffff"}>L’édition et la direction de la publication du Site est assurée par Dos Santos Vera, domiciliée 1380 rue de la bretechelle, 78370 Plaisir, dont le numéro de téléphone est 0625934881 et l'adresse e-mail vera78ds@gmail.com.</Typography>
                    <Typography variant="h2" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 2 - L'HEBERGEUR</Typography>
                    <Typography variant="body1" color={"#ffffff"}>L'hébergeur du Site est Dos Santos Vera, domiciliée 1380 rue de la bretechelle, 78370 Plaisir, dont le numéro de téléphone est 0625934881 et l'adresse e-mail vera78ds@gmail.com.</Typography>
                    <Typography variant="h2" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 3 - ACCES AU SITE</Typography>
                    <Typography variant="body1" color={"#ffffff"}>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.</Typography>
                    <Typography variant="body1" color={"#ffffff"}>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</Typography>
                    <Typography variant="h2" color={"#ffffff"} margin={"20px"} fontSize={"40px"}>ARTICLE 4 - COLLECTE DES DONNEES</Typography>
                    <Typography variant="body1" color={"#ffffff"}>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</Typography>
                    <Typography variant="body1" color={"#ffffff"}>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :</Typography>
                    <Typography variant="body1" color={"#ffffff"}>· par mail à l'adresse email vera78ds@gmail.com</Typography>
                    <Typography variant="body1" color={"#ffffff"}>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</Typography>
            </Container>
        </div>
    );
};

export default Mentions;
